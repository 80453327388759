// import './src/styles/theme.css'
import './static/blog/40/css-variables.js'

const zoomImages = () => {
  const container = document.createElement('div')
  container.style.width = '100vw'
  container.style.height = '100vh'
  container.style.display = 'none'
  container.style.justifyContent = 'center'
  container.style.alignItems = 'center'
  container.style.backgroundColor = 'rgb(0, 0, 0, 0.8)'
  container.style.position = 'fixed'
  container.style.top = 0
  container.style.left = 0

  const preview = document.createElement('img')
  preview.style.maxWidth = '90%'
  preview.style.maxHeight = '90%'
  preview.style.objectFit = 'cover'
  preview.style.borderRadius = '4px'

  container.append(preview)
  document.body.append(container)

  const keyDownHandler = event => {
    if (event.key === 'Escape') close()
  }

  const close = () => {
    container.style.display = 'none'
    window.removeEventListener('keydown', keyDownHandler)
    document.body.style.overflowY = 'auto'
  }

  const open = src => {
    preview.src = src
    container.style.display = 'flex'
    window.addEventListener('keydown', keyDownHandler)
    document.body.style.overflowY = 'hidden'
  }

  const images = document.querySelectorAll('.layout img')
  images.forEach(image => {
    image.addEventListener('click', () => {
      console.log(image.src)
      open(image.src)
    })
  })

  container.addEventListener('click', close)
}

window.addEventListener('load', zoomImages, false)
